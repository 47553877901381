<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="associationId"
            class="body-2"
          >
            <i>#{{ association.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="association.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="abbreviation"
              >
                <v-text-field
                  v-model="association.abbreviation"
                  hide-details="auto"
                  label="Abreviação"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="cities"
              >
                <v-autocomplete
                  v-model="association.cities"
                  item-value="id"
                  item-text="name"
                  label="Prefeituras"
                  hide-details="auto"
                  multiple
                  auto-select-first
                  hide-selected
                  :items="cities"
                  :error-messages="errors"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.name"
                      close
                      label
                      small
                      @click="data.select"
                      @click:close="removeSelectedCity(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import associationsApi from '@/api/associations'
  import citiesApi from '@/api/cities'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      associationId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        association: {},
        cities: []
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      title () {
        return this.associationId ? 'Editar' : 'Adicionar'
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    mounted () {
      this.loadCities()
    },

    methods: {

      async load () {
        try {
          this.association = {
            name: null,
            abbreviation: null,
            cities: []
          }

          if (!this.associationId) {
            return
          }

          this.loading = true

          const response = await associationsApi.get(this.associationId)

          this.association = response.data.association
          this.association.cities = this.association.cities.map((city) => city.cityId)
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          if (this.associationId) {
            await associationsApi.update(this.associationId, this.association)
          } else {
            await associationsApi.insert(this.association)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.associationId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

      async loadCities () {
        try {
          this.loading = true

          const response = await citiesApi.list({
            limit: 1000,
            offset: 0,
          })

          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      removeSelectedCity (item) {
        const index = this.association.cities.indexOf(item.id)
        if (index >= 0) this.association.cities.splice(index, 1)
      },

    },

  }
</script>
